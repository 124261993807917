<template>
  <div class="border border-neutral-200 rounded-md hover:shadow-lg h-full">
    <div class="relative h-56 lg:h-72 overflow-hidden">
      <NuxtLink :to="link" class="block">
        <NuxtImg
          v-if="image"
          :src="image"
          format="webp"
          :alt="product.name"
          loading="lazy"
          class="block object-contain w-auto h-56 lg:h-72 mx-auto rounded-md aspect-square"
          width="300"
          height="300"
        />
      </NuxtLink>
      <!-- <div class="absolute bottom-0 left-0">
        <span v-if="product.availability == 'last_remaining_items'" class="bg-red-100 block text-red-900 p-1">
          {{ $t('products.last_remaining_item' + (product.quantity > 1 ? 's' : ''), { count: product.quantity }) }}
        </span>
      </div> -->
      <!-- <div class="absolute top-2 left-2" v-if="product.specific_prices && parseInt(product.specific_prices.price) !== -1">
        <div class="flex items-center justify-center bg-red-600 rounded text-sm px-3 h-7 text-white">{{ $t('Offer') }}
        </div>
      </div> -->
      <div class="absolute bottom-2 left-2" v-if="product.cart_rules && product.cart_rules.name">
        <span class="py-0 px-3 h-7 items-center font-bold text-base rounded bg-green-100 text-green-700 inline-flex shadow">
          {{ product.cart_rules.name }}
        </span>
      </div>
    </div>
    <div class="p-4 border-t border-neutral-200 flex flex-col h-[calc(100%-224px)] lg:h-[calc(100%-288px)]">
      <div class="flex flex-col grow h-full">
        <SfLink :href="link" variant="secondary" class="text-base font-medium no-underline line-clamp-2">
          {{ product?.name }}
        </SfLink>
        <VProductPrice
          :prices-data="{
            specific_prices: product.specific_prices,
            price: product.price,
            regular_price: product.regular_price,
            discount_percentage: product.discount_percentage,
          }"
          container-styles="pt-3 pb-4"
        />
        <span class="flex flex-grow text-sm mb-1">
          {{ product.delivery_information }}
        </span>
      </div>
      <div class="flex gap-2 flex-shrink-0">
        <button
          :disabled="loading || !isAvailable"
          class="btn btn-primary w-full !text-xs"
          v-if="product?.id_product_attribute == '0'"
          size="sm"
          @click="
            addToCart({
              ...product,
              id_product: product?.id_product,
              id_product_attribute: product?.id_product_attribute,
              qty: 1,
            })
          "
        >
          <SfProgressCircular v-show="loading" />
          <SfIconShoppingCart size="sm" class="mr-2" />
          {{ buttonText }}
        </button>
        <NuxtLink v-else :to="link" class="btn btn-primary">
          {{ buttonText }}
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {
    SfIconShoppingCart,
    SfLink,
    SfProgressCircular
} from '@storefront-ui/vue';
import type { GetProductResponse } from '@vue-storefront/prestashop-api';
import VProductPrice from './VProductPrice.vue';

const props = defineProps<{ product: GetProductResponse['psdata'] }>();

const { t } = useI18n();
const localePath = useLocalePath();
const { execute: addToCart, loading } = useAddToCart();
const product = computed(() => props.product);

const { isAvailable } = useCartItem(product);

const link = computed(() => {
  return localePath(`/${props.product?.id_product}-${props.product?.link_rewrite}.html`);
});

const image = computed(() => {
  return (
    product.value.default_image?.large?.url?.replace('home_default', 'large_default') ??
    product.value.default_image?.url.replace('home_default', 'large_default')
  );
});

const buttonText = computed(() => {
  if (loading.value) {
    return t('Loading') + '...';
  }

  if (props.product.id_product_attribute == '0') {
    return t('Add to cart');
  }

  return t('View product');
});
</script>
